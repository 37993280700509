@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

body {
  font-family: "Lato", sans-serif;
  margin: 0;
  background-color: rgb(222, 220, 218);
}

h1 {
  font-weight: 200;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 1.3rem;
}

p {
  font-weight: 200;
}

ul {
  list-style: none;
}

iframe {
  width: 100%;
}

.contact {
  text-align: center;
  padding: 4%;
}

.film {
  margin: 0;
  padding: 1px;
}

.contents {
  padding: 4%;
  min-height: 100vh;
  text-align: justify;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7%;
  background-color: rgb(69, 64, 60);
  color: whitesmoke;
  position: relative;
}

.button {
  padding: 1%;
  background-color: lightgray;
  border: 0.5 solid gray;
  border-radius: 10px;
}

.button:hover {
  text-decoration: none;
}

.choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  margin: 2%;
  color: rgb(5, 76, 94);
  width: 100%;
}

.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.portfolio-piece {
  margin: 10px 0;
  background-color: white;
  border-radius: 9px;
  padding: 15px;
}

.header {
  font-size: 64px;
}
h1 {
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  h1 {
    margin-top: 30px;
  }
  h3 {
    font-size: 25px;
  }
  .header {
    font-size: 20px;
  }

  .subtitle {
    font-size: 20px;
  }
}

.biog {
  font-weight: 100;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.store {
  display: flex;
  flex-wrap: wrap;
}

.item {
  background-color: whitesmoke;
  padding: 4%;
  margin: 1%;
  box-shadow: 5px 5px lightgray;
  border-radius: 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery img {
  height: 250px;
  margin: 3%;
}
.main-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background-color: black;
  color: white;
}

.music-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
}

.music-nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.photo {
  width: 85%;
}

.photo-container {
  margin: auto;
  display: flex;

  flex-wrap: wrap;
}

.review {
  text-align: center;
  background-color: whitesmoke;
  width: 90%;
  margin: 2% auto;
  padding: 3%;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box {
  position: relative;
  z-index: 1;
}
.overlay p {
  text-align: center;
  margin-top: 35%;
}

.overlay img {
  width: 70%;
  margin: auto;
}
.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #05050598;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-box:hover .overlay {
  opacity: 0.9;
}

@font-face {
  font-family: "billabongregular";
  src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot");
  src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.woff")
      format("woff"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.ttf")
      format("truetype"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.svg#billabongregular")
      format("svg");
}

.blog-title {
  font-family: "billabongregular";
  font-size: 5rem;
  text-align: center;
}

.social {
  margin-left: 25px;
}

.portrait {
  display: flex;
}

.portrait img {
  height: 250px;
}

.logo {
  width: 35%;
  margin-bottom: 5%;
}

.logos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.webdev {
  background-image: linear-gradient(
      rgba(250, 250, 250, 0.9),
      rgba(250, 250, 250, 0.9)
    ),
    url("https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#shopping-cart {
  color: white;
}

#shopping-cart:hover {
  cursor: pointer;
  color: olive;
}

img {
  object-fit: contain;
}

.spin {
  animation-name: spin;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
