.drawerlogo {
  width: 75px;
}

.side-drawer {
  height: 100%;
  background-color: rgb(46, 43, 41);
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: 300ms ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer li {
  margin-bottom: 4%;
}

.side-drawer ul {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  list-style: none;
}

.side-drawer a {
  color: whitesmoke;
  text-decoration: none;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: rgb(55, 149, 204);
}

.drawer-header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
}

.social {
  color: whitesmoke;
  display: flex;
  justify-content: space-around;
}
